<template>
  <div>
    <h5 class="mb-1">
      Filter group
    </h5>
    <div
      v-for="(filter, indexRow) in filterGroup.query.children"
      :key="indexRow"
    >
      <p
        v-if="indexRow > 0"
        :key="`groupOperator${index}`"
        class="text-capitalize text-primary font-weight-bold"
      >{{ filterGroup.query.logicalOperator }}</p>
      <FilterRow
        :key="indexRow"
        :filter="filter"
        :fields-options="fieldsOptions"
        :index="indexRow"
        :show-buttons="indexRow == filterGroup.query.children.length - 1"
        :is-group-row="true"
        :group-logical-operator="filterGroup.query.logicalOperator"
        class="cursor-default"
        @removeFilterRow="removeFilterRow"
        @addAndFilter="addFilterRow"
        @addOrFilter="addFilterRow"
      />
    </div>
  </div>

</template>

<script>
import FilterRow from '@/views/admin/reports-v2/reports/report-details/filters-modal-v2/FilterRow.vue'

export default {
  name: 'FilterGroup',
  components: {
    FilterRow,
  },
  props: {
    filterGroup: {
      type: Object,
      default: () => {},
    },
    fieldsOptions: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    removeFilterGroup() {
      this.$$emit('removeFilterGroup')
    },
    addFilterRow() {
      this.filterGroup.query.children.push({
        type: 'query-builder-rule',
        query: {
          rule: '',
          operator: '',
          value: '',
        },
      })
    },
    removeFilterRow(index) {
      this.filterGroup.query.children.splice(index, 1)
      if (this.filterGroup.query.children.length === 1) {
        const [filterRow] = this.filterGroup.query.children
        this.$emit('replaceFilterGroupWithFilterRow', filterRow)
      }
    },
  },
}
</script>

<style>

</style>
