<template>
  <ValidationObserver
    ref="bulk-change-applications-status"
  >
    <b-modal
      id="bulk-change-applications-status-modal"
      ref="bulk-change-applications-status-modal"
      centered
      :title="'Edit Status For Applications (' + totalLength + ' selected)'"
      ok-title="Save"
      ok-only
      no-close-on-backdrop
      @hidden="closeModal"
      @show="prepareData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            v-if="withExecute"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Field with available application ID"
            >
              <validation-provider
                #default="{ errors }"
                name="destination field"
                rules="required"
              >
                <v-select
                  v-model="targetField"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="destinationTypeOptions"
                  :reduce="option => option.value"
                  :clearable="false"
                  :placeholder="'Select Field'"
                  :disabled="!destinationTypeOptions.length"
                  :class="{'is-invalid': errors.length > 0 }"
                  @input="handleTargetIdSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Status"
            >
              <v-select
                v-model="newStatus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusesList"
                :clearable="false"
                label="name_with_user_type"
                :placeholder="'Select Status'"
                :disabled="!targetField && withExecute"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="newStatus && newStatus.is_acceptable"
            sm="12"
            class="mb-1"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="withoutCharging"
                class="d-inline-flex justify-content-center align-items-center"
              >
                Accept without charging
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading || !newStatus"
          @click="handleForm"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useBulkChangeApplicationsStatus from '@/views/components/bulk-change-applications-status/useBulkChangeApplicationsStatus'
import useReportDetails from '@/views/admin/reports-v2/reports/report-details/useReportDetails'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BOverlay,
    BButton,
    vSelect,
  },
  props: {
    markedElements: {
      type: Array,
      required: true,
    },
    fieldsOptions: {
      type: [Array, Object],
      default: () => {},
    },
    executeData: {
      type: Object,
      default: () => {},
    },
    allElementsChecked: {
      type: Boolean,
      default: () => false,
    },
    withExecute: {
      type: Boolean,
      default: () => false,
    },
    filterName: {
      type: String,
      default: null,
    },
    programId: {
      type: String,
      default: null,
    },
    totalElementsCount: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      newStatus: null,
      withoutCharging: false,
      statusesList: [],
      isLoading: false,
      destinationTypeOptions: [],
      targetField: null,
      selectedApplications: [],
      totalLength: 0,
      required,
    }
  },
  setup() {
    const {
      fetchStatusesList,
      updateApplicationStatuses,
    } = useBulkChangeApplicationsStatus()

    const {
      fetchResultsByTargetField,
    } = useReportDetails()

    return {
      fetchStatusesList,
      updateApplicationStatuses,
      fetchResultsByTargetField,
    }
  },
  methods: {
    closeModal() {
      this.newStatus = null
      this.withoutCharging = false
      this.selectedApplications = []
      this.destinationTypeOptions = []
      this.targetField = null
      this.$refs['bulk-change-applications-status-modal'].hide()
    },
    async elementsCount() {
      this.totalLength = this.allElementsChecked ? this.totalElementsCount : this.markedElements.length
    },
    async prepareData() {
      if (this.withExecute) {
        this.destinationTypeOptions = this.fieldsOptions
      }
      await this.prepareStatusesList()
      await this.elementsCount()
    },
    async prepareStatusesList() {
      const queryParam = {
        program_id: this.programId,
        sortBy: 'position',
      }
      this.statusesList = await this.fetchStatusesList(queryParam)
    },
    async handleTargetIdSelected() {
      if (this.allElementsChecked) {
        const queryParams = {
          executeData: this.executeData,
          targetField: this.targetField,
        }
        if (this.programId) {
          queryParams.programId = this.programId
        }
        this.selectedApplications = await this.fetchResultsByTargetField(queryParams)
          .then(response => response.data)
      } else {
        this.selectedApplications = this.markedElements.map(element => element[this.targetField])
      }
      await this.elementsCount()
    },
    handleForm() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs['bulk-change-applications-status'].validate().then(success => {
          if (success) {
            resolve(true)
            this.handleChangeStatuses()
          } else {
            reject()
          }
        })
      })
    },
    async handleChangeStatuses() {
      const queryParams = {
        status_id: this.newStatus.id,
        without_charging: !!this.withoutCharging,
        ids: this.selectedApplications,
      }
      this.isLoading = true
      await this.updateApplicationStatuses(queryParams)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
