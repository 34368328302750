<template>
  <!-- Report Type List Modal -->
  <b-modal
    id="report-view-list-modal"
    ref="report-view-list-modal"
    centered
    title="Report View"
    ok-title="Ok"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @hidden="resetModal"
    @ok="setReportView"
    @show="fetchReportViews"
  >
    <b-row>
      <b-col
        sm="12"
        class="mb-1"
      >
        <b-form-group
          label="Report View List"
        >
          <v-select
            v-model="selectedReportView"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="reportViewOptions"
            label="name"
            value="id"
            :placeholder="reportViewPlaceholder"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import useReportsView from '@/views/admin/reports-v2/reports-view/useReportsView'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,

    vSelect,
  },
  data() {
    return {
      selectedReportView: null,
      reportViewOptions: [],
      reportViewPlaceholder: 'Loading...',
    }
  },
  setup() {
    const {
      fetchReportViewsList,
    } = useReportsView()

    return {
      fetchReportViewsList,
    }
  },
  methods: {
    async fetchReportViews() {
      const response = await this.fetchReportViewsList()
      if (this.$route.query.report_program_id) {
        this.reportViewOptions = response.filter(item => item.with_program_filter)
      } else {
        this.reportViewOptions = response
      }
      this.reportViewPlaceholder = 'Select Report View'
    },
    resetModal() {
      this.selectedReportView = null
    },
    setReportView() {
      this.$emit('setReportView', this.selectedReportView)
    },
  },
}
</script>
