<template>
  <b-modal
    id="filters-modal"
    ref="filters-modal"
    title="Filters"
    centered
    no-close-on-backdrop
    no-enforce-focus
    ok-title="Apply"
    cancel-variant="outline-secondary"
    size="lg"
    class="p-0"
    @show="setModalData"
    @ok="saveFilters"
  >
    <b-row>
      <b-col
        cols="3"
      >
        <b-form-group
          label="Logical Operator"
        >
          <v-select
            v-model="filters.logicalOperator"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="logicalOperatorOptions"
            label="text"
            :reduce="option => option.value"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <template
      v-for="(filter, index) in filters.children"
    >
      <p
        v-if="index > 0"
        :key="`operator${index}`"
        class="text-capitalize text-primary font-weight-bold"
      >{{ filters.logicalOperator }}</p>
      <b-card
        :key="index"
        class="mb-1"
      >
        <FilterRow
          v-if="filter.type == 'query-builder-rule'"
          :key="index"
          :filter="filter"
          :fields-options="fieldsOptions"
          :index="index"
          class="cursor-default"
          @removeFilterRow="removeFilterRow"
          @addAndFilter="addGroupFilter($event, 'and')"
          @addOrFilter="addGroupFilter($event, 'or')"
        />

        <FilterGroup
          v-if="filter.type == 'query-builder-group'"
          :key="index"
          :filter-group="filter"
          :fields-options="fieldsOptions"
          :index="index"
          class="cursor-default"
          @replaceFilterGroupWithFilterRow="replaceFilterGroupWithFilterRow($event, index)"
        />

      </b-card>
    </template>

    <!-- Add Button -->
    <div
      class="d-flex align-items-center justify-content-center"
    >
      <b-button
        variant="outline-primary"
        class="d-flex align-items-center justify-content-center"
        @click="addNewFilter"
      >
        <feather-icon
          icon="PlusCircleIcon"
          size="25"
          class="mr-1 cursor-pointer"
        />
        Add filter
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import FilterRow from '@/views/admin/reports-v2/reports/report-details/filters-modal-v2/FilterRow.vue'
import FilterGroup from '@/views/admin/reports-v2/reports/report-details/filters-modal-v2/FilterGroup.vue'

export default {
  name: 'FiltersModal',
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,

    vSelect,

    FilterRow,
    FilterGroup,
  },
  props: {
    filtersData: {
      type: Object,
      default: () => {},
    },
    fieldsOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filters: {
        children: [],
        logicalOperator: 'and',
      },
      logicalOperatorOptions: [
        { value: 'and', text: 'And' },
        { value: 'or', text: 'Or' },
      ],
    }
  },
  methods: {
    setModalData() {
      this.filters = JSON.parse(JSON.stringify(this.filtersData))
    },
    addNewFilter() {
      this.filters.children.push({
        type: 'query-builder-rule',
        query: {
          rule: '',
          operator: '',
          value: '',
        },
      })
    },
    removeFilterRow(index) {
      this.filters.children.splice(index, 1)
    },
    saveFilters() {
      this.$emit('saveFilters', this.filters)
    },
    addGroupFilter(index, logicalOperator) {
      const rule = this.filters.children[index]
      const newGroup = {
        type: 'query-builder-group',
        query: {
          children: [
            rule,
            {
              type: 'query-builder-rule',
              query: {
                rule: '',
                operator: '',
                value: '',
              },
            },
          ],
          logicalOperator,
        },
      }
      this.filters.children.splice(index, 1, newGroup)
    },
    replaceFilterGroupWithFilterRow(filterRow, index) {
      this.filters.children.splice(index, 1, filterRow)
    },
  },
}
</script>

<style>

</style>
