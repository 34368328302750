import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBulkChangeApplicationsStatus() {
  // Use toast
  const toast = useToast()

  const fetchStatusesList = queryParams => axios
    .get('/auth/registration-form-statuses', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Statuses',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const updateApplicationStatuses = queryParams => axios
    .post('/auth/bulk-application-statuses', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application Statuses was successfully changed',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error change Application Statuses',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchStatusesList,
    updateApplicationStatuses,
  }
}
