<template>
  <ValidationObserver
    ref="saveReportModal"
  >
    <b-modal
      id="save-report-modal"
      ref="save-report-modal"
      :title="title"
      centered
      no-close-on-backdrop
      :ok-title="title"
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      @ok.prevent="saveReport"
    >
      <b-form-group
        label="Report Title"
      >
        <ValidationProvider
          #default="{ errors }"
          name="Report Title"
          rules="required"
        >
          <b-form-input
            v-model="name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { BModal, BFormGroup, BFormInput } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    reportName: {
      type: String,
      default: '',
    },
    reportType: {
      type: String,
      default: 'save',
    },
  },
  data() {
    return {
      name: this.reportName,
      required,
    }
  },
  computed: {
    title() {
      return this.reportType === 'save' ? 'Save Report' : 'Save As'
    },
  },
  watch: {
    reportName(val) {
      this.name = val
    },
  },
  methods: {
    saveReport() {
      return new Promise((resolve, reject) => {
        this.$refs.saveReportModal.validate().then(success => {
          if (success) {
            resolve(true)
            this.$emit('saveReport', this.name)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style>

</style>
